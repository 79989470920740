import { Component } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { SubscribeDialogComponent } from '../../../shared/modals/subscribe-dialog/subscribe-dialog.component';
import { environment } from '../../../../environments/environment';
import { BrowserService } from '../../browser.service';
import { HeaderService } from '../../header.services';
import { VersionService } from '../../services/version.services';
import { Unsubscriber } from '../../unsubscriber/unsubscriber';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';

@Component({
  selector: 'brf-news-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent extends Unsubscriber {
  currentVersion: string;
  lastestVersion: string = '';
  isDifferentVersion: boolean = true;
  shouldShowFooter = true;
  year: number = new Date().getFullYear();

  constructor(private browserservice: BrowserService, private versionService: VersionService, private headerService: HeaderService, public dialog: MatDialog, private router: Router) {
    super();
    this.currentVersion = environment.version;
    if (this.browserservice.isbrowser) {
      this.addSubscription(
        this.versionService.getLatestVersion().subscribe((ver: string) => {
          this.lastestVersion = ver;
          this.isDifferentVersion = ver && ver.length > 0 && ver !== this.currentVersion;
        })
      );

      router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          if (window.location.href.indexOf('#prenumerera') > 0) {
            this.openSubscribeModal();
          }
        }
      });
    }
  }

  ngOnInit(): void {
    this.addSubscription(this.headerService.showHeaderSubject.subscribe(flag => (this.shouldShowFooter = flag)));
  }
  openSubscribeModal() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {};
    this.dialog.open(SubscribeDialogComponent, dialogConfig);
  }
  update() {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistrations().then(function (registrations) {
        let pArr: Promise<boolean>[] = [];
        for (let registration of registrations) {
          pArr.push(registration.unregister());
        }
        if ('caches' in window) {
          caches.keys().then(function (keyList) {
            for (let k of keyList) pArr.push(caches.delete(k));
          });
        }
        Promise.all(pArr).then(result => {
          setTimeout(() => {
            location.reload();
          }, 500);
        });
      });
    }
  }

  showCookieSettings() {    
    if (this.browserservice.isbrowser) {
      AppComponent.instance?.showCookieConsent();
    }
  }
}